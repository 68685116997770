import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { Tabs, Tab, Col, Row } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const SoloGuidesGuild: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo solo-guide'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Guilds and Guild Boss</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_guild.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Guilds and Guild Boss</h1>
          <h2>
            Everything about Guilds and Guild Boss in Solo Leveling: Arise
            explained.
          </h2>
          <p>
            Last updated: <strong>14/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction to Guilds" />

        <p>
          With the 100th Day’s Celebration update on August 22, 2024 , the
          developers finally introduced Guild content to Solo Leveling Arise.
        </p>
        <p>
          Let us go over details of what Guilds have to offer along with Guild
          Boss content:
        </p>
        <ul>
          <li>Guild can be accessed by tapping "Guild' Icon in the menu.</li>
          <li>
            Joining a Guild grants access to Weekly Guild Check-In, which grants
            players up to 200 weekly Guild Coins to be spent in exclusive Guild
            Shop featuring a lot of goodies.
          </li>
          <li>
            In Guild Lobby, you will be greeted by Avatars and names of all the
            Players in the Guild and access various Guild Activities.
          </li>
        </ul>
        <StaticImage
          src="../../../images/solo/generic/guild_1.webp"
          alt="Guide"
        />
        <ul>
          <li>
            You can access Activities by tapping directly on the screen, or by
            tapping a symbol on upper-right corner of screen to open Guild
            Activities menu, which will look like this:
          </li>
        </ul>
        <StaticImage
          src="../../../images/solo/generic/guild_2.webp"
          alt="Guide"
        />
        <ul>
          <li>
            Interacting with Jian lets you access the Check-in Window as well as
            Guild information.
          </li>
          <li>
            Inside the Guild information page, you can check the Member list,
            their contribution score and check the latest SSR Characters or
            Weapons obtained by them via Guild Logs! (Be ready for that Sodium
            spike in your blood!)
          </li>
          <li>
            While being part of a Guild, you will get the opportunity to
            complete Guild Missions, these are separate Daily and Weekly
            Missions that provide players Guild Coins and Guild Exp to help
            level up your Guild. As Guild Level increases, more items are added
            to Guild Shop as well as unlocking more Guild customization options
            for the Leaders.
          </li>
        </ul>
        <StaticImage
          src="../../../images/solo/generic/guild_3.webp"
          alt="Guide"
        />
        <ul>
          <li>
            The Guild Shop is similar to Celebration Coin Shop in functionality
            outside of using Guild Coins instead. This shop features lots of
            great rewards such as an exclusive SSR Light Weapon for Sung Jinwoo,
            extra entries for Workshop of Brilliant Light and Weapon Banner
            Tickets among others!
          </li>
        </ul>
        <StaticImage
          src="../../../images/solo/generic/guild_4.webp"
          alt="Guide"
        />
        <ul>
          <li>
            Guild lobby also features an array of Mini Games (Rotational Basis)
            you can play and compete with your fellow Guild Members, while they
            don’t give any special rewards, they add a fun element to the game!
          </li>
        </ul>
        <SectionHeader title="Guild Boss" />
        <p>
          One of the perks of joining a Guild is being able to participate in
          Guild Boss - twice a week. This fight is similar to PoD, which you can
          run repeatedly and it will be only counted when you register the
          score.
        </p>
        <StaticImage
          src="../../../images/solo/generic/guild_5.webp"
          alt="Guide"
        />
        <p>
          Guild Boss features Sung Jinwoo and 9 Hunters, divided into 3 Teams of
          3. Its goal is to defeat the featured enemy, as many times as possible
          within the allotted duration. Everytime Boss hits 0 HP, it gets
          enraged - recovering HP and gaining higher stats.
        </p>
        <p>
          Guild Boss provides two different rewards - Individual and Guild
          Rewards. Individual rewards are given after you have used your
          entries, depending on the count of Boss Enrages achieved. Whereas,
          Co-Op reward is given at end of the season based on the Guild’s
          overall contribution on Daily Reset every Thursday.
        </p>
        <p>Guild Ranking is present but offers no benefit as of now.</p>
        <p>
          Let us move on to actual Guild Boss fight now, for starters as
          mentioned above Hunters are divided into 3 Teams of 3, as shown below:
        </p>
        <StaticImage
          src="../../../images/solo/generic/guild_6.webp"
          alt="Guide"
        />
        <p>Some Tips:</p>
        <ul>
          <li>
            Team buffs are exclusive to that team, debuffs applied on enemies
            can be utilized by anyone.
          </li>
          <li>
            As such, team building focus will be on inflicting as many as
            debuffs as possible on the enemy, while buffing your teammates to
            increase overall DPS by a lot. Units such as Lee Bora and Han Semi
            especially, will shine in this mode whereas pure buffer units
            without much personal damage will be replaced overtime.
          </li>
          <li>
            Sylph Blessing Set is extremely powerful in Guild Boss and Han
            Se-Mi’s Golden Meadow skill will provide a Shield to anyone standing
            in its area, proccing the Guardian Set effect of every team that has
            the set among their members, as such you will see these two running
            together often on supports.
          </li>
        </ul>
        <p>
          Currently the first two teams function as pure DPS builds, while the
          third team is the debuff squad. Let us take a look at current Guild
          Boss meta:
        </p>
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs"
          className="nav-tabs"
        >
          <Tab eventKey="Team1" title={<> Team A</>} className="with-bg">
            <h5 className="endless"> Team A</h5>
            <p>
              Team 1 is usually built with the premise of using Sung Jinwoo as
              their support, as his DPS over long fights is lacking compared to
              top Hunters in the game. As such he is better used as support,
              using the Blessing Set.
            </p>
            <p>
              Ideally, your Team 1 will consist of Cha Hae-In, Go Gunhee and
              Meilin Fisher. Cha and Gunhee make for a strong duo that deal tons
              of damage while Meilin buffs both making her the perfect third
              slot.
            </p>
            <p>Set Suggestions:</p>
            <ul>
              <li>Sung Jinwoo - 8 Piece Blessing Set.</li>
              <ul>
                <li>
                  Highly advised to reset your stats to Full INT build for this
                  fight.
                </li>
              </ul>
              <li>Cha Hae-In - 4x Curse + 4x Expert.</li>
              <li>Go Gunhee - 4x Warmonger + 4x Curse/ 8x Greed.</li>
              <ul>
                <li>
                  If your Cha isn’t highly invested in but your Gunhee is, you
                  may want to build Gunhee as a pure Damage dealer with the
                  Warmonger + Curse set.
                </li>
              </ul>
              <li>Meilin - 4x Guardian Set + 4x Sylph Blessing.</li>
            </ul>
          </Tab>
          <Tab eventKey="Team2" title={<> Team B</>} className="with-bg">
            <h5 className="endless"> Team B</h5>
            <p>
              Team 2 is primarily a playground for Amamiya Mirei and Han Se-Mi,
              whereas your third unit will be most commonly Choi Jong-In, owing
              to his Defense Down debuff and will be wearing Blessing Set.
            </p>
            <p>Set Suggestions:</p>
            <ul>
              <li>Amamiya Mirei - 4x Curse + 4x Expert.</li>
              <li>
                Han Se-Mi - 4x Guardian/Angel/One-Hit Kill + 4x Sylph Blessing.
              </li>
              <li>Choi Jong-In - 8x Blessing.</li>
              <ul>
                <li>
                  It is important to note, his performance as support is highly
                  dependent on his Exclusive Weapon as it reduces the target's
                  Defense when inflicted with [Burn]. As such players looking to
                  maximize Guild Boss scores should consider investing in it,
                  with his personal A5 also improving his power gain
                  significantly.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab eventKey="Team3" title={<> Team C</>} className="with-bg">
            <h5 className="endless"> Team C</h5>
            <p>
              As mentioned earlier, Team 3 is focused around debuffing enemies
              to amplify the team's damage. The usual units to run would be Lee
              Bora, Anna Ruiz and Min Byung-Gu. (Anna Ruiz being replaceable by
              Alicia Blanche depending on her advancement and power).
            </p>
            <p>Set Suggestions:</p>
            <ul>
              <li>Anna Ruiz - 4x Destroyer Set + 4x Greed.</li>
              <ul>
                <li>Alicia Blanche - 8x Curse/ 4x Curse + 4x Expert.</li>
              </ul>
              <li>
                Lee Bora - 4x Curse + 4x Expert (If running Anna Ruiz) /
                Blessing Set (If running DPS Alicia).
              </li>
              <li>Min Byung Gu - 4x Guardian Set + 4x Sylph.</li>
            </ul>
          </Tab>
        </Tabs>
        <h6>Sung Jinwoo Build</h6>
        <p>
          As for Sung Jinwoo, your weapon of choice will be Moonshadow Daggers
          along with Book of Thetis, owing to their powerful debuffs and team
          buffs - especially Moonshadow, after the recent A5 bonus. Set wise, as
          discussed above - he should be using the 8x Blessing Set to fulfill
          his role as a support. Blessing Stones such as Bloodlust, or the
          recently added “We are One” should be used as long as running mono
          element teams to further reinforce his role as a support.
        </p>
        <p>
          At the end of the fight, you will be greeted by your team’s Individual
          as well as Overall Damage, if satisfied you can register your score,
          otherwise re-run the fight.
        </p>
        <StaticImage
          src="../../../images/solo/generic/guild_7.webp"
          alt="Guide"
        />
        <p>
          For those that would also like some video references, here is a super
          useful video linked below:
        </p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="RUK6KoVlztk" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesGuild;

export const Head: React.FC = () => (
  <Seo
    title="Guilds and Guild Boss | Solo Leveling: Arise | Prydwen Institute"
    description="Everything about Guilds and Guild Boss mode in Solo Leveling: Arise explained."
    game="solo"
  />
);
